:root {
    --primary-color: #001971;  /* Custom primary color */
    --secondary-color: #3900E9;  /* Custom primary color */
    --text-color: #ffffff;     /* Custom text color */
    --surface-border: #001971; /* Custom border color */
    --border-radius: 10px;      /* Custom border radius */
 }
 
 .p-button {
    color: var(--text-color) !important;
    border-radius: var(--border-radius) !important;
    background: linear-gradient(135deg, rgba(0, 0, 0, 1), rgba(0, 25, 113, 1)) !important;
    border-color: #001971;
    box-shadow: 0 2px 3px 2px rgba(25, 25, 25, .3);
    border: 0;
    color: white !important;
    height: 48px;
    padding: 0 30px;
}

/* Contained Variant */
.p-button-contained {
   background: linear-gradient(135deg, rgba(0, 0, 0, 1), rgba(0, 25, 113, 1)) !important;
   border: 0 !important;
   box-shadow: 0 2px 3px 2px rgba(25, 25, 25, 0.3);
   color: var(--text-color) !important;
 }
 
 /* Outlined Variant */
 .p-button-outlined {
   background: transparent !important;
   border: 1px solid var(--surface-border) !important;
   color: var(--primary-color) !important;
   box-shadow: none !important;
 }

 
 .p-datepicker-trigger {
    color: var(--text-color) !important;
    border-radius: var(--border-radius) !important;
    background: 'linear-gradient(135deg,rgba(0,0,0,1), rgba(0,25,113, 1)) !important';
    box-shadow: '0 2px 3px 2px rgba(25, 25, 25, .3)';
    border: 0;
    color: 'white';
    height: 48;
    padding: '0 30px'
 }
 
 /* .p-highlight {
    color: var(--secondary-color) !important;;  
 } */

 .p-button-rounded.p-button-text {
   background: unset !important;
   border-color: unset !important;
   box-shadow: unset !important;
   color: #001971 !important;
   border: 0;
 }

 .p-tabview-nav-link {
   color: #2d4d76 !important;
   font-family: Poppins !important;
   font-weight: bold !important;
   font-size: 18px !important;
 }
 .p-tabview-ink-bar {
   background-color: #2d4d76 !important;
 }
 