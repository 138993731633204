body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.realtimemap-tooltip {
  position: absolute;
  z-index: 100;
  font-size: 12px;
  padding: 16px;
  border-radius: 8px;
  background: rgba(33, 49, 49, 0.8);
  color: #fff;
  min-width: 200px;
  max-height: 240px;
  background: #fcfcfc;
  color: #444;
  overflow-y: scroll;
}

.tooltip {
  pointer-events: none;
  position: absolute;
  z-index: 10000;
  font-size: 12px;
  padding: 16px;
  border-radius: 8px;
  background: rgba(33, 49, 49, 0.8);
  color: #fff;
  min-width: 100px;
  max-height: 240px;
  overflow-y: scroll;
}

.tooltip .title {
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 14px;
  color: #daefff;
}

.tooltip .drow {
  display: flex;
}

.tooltip .drowlabel {
  flex-grow: 1;
  font-family: 'Poppins';
  font-size: 12px;
  color: #daefff;
}

.tooltip .drowvalue {
  font-family: 'Poppins';
  font-size: 12px;
  color: #daefff;
  font-weight: bold;
  padding-left: 10px;
}

.tooltip.interactive {
  pointer-events: all;
  background: #fcfcfc;
  color: #444;
  overflow-y: auto;
}

/* .p-multiselect {
  border: none;
  border-radius: 5
} */

.p-inputtext .p-component {
  border: none;
  border-radius: 5
}