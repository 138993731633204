.rebranding-banner {
    background-color: #001971;    ;
    color: #fff;
    text-align: center;
    padding: 2px 0;
    font-size: 16px;
    font-weight: bolder;
    position: absolute;
    top: 72px;
    left: 96px;
    width: -webkit-fill-available;
    height: 30px;
    z-index: 999;
  }

  .rebranding-banner.orgBanner {
    left: 72px;
    top: 64px;
  }

  .rebranding-banner.loginBanner {
    left: 0px;
    top: 0px;
    height: 42px;
  }

  .rebranding-link {
    color: #00ffeb;
  }
  